import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Global, css } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import SEO from '../components/organisms/seo';
import { media } from '../utils/media-queries';
import RichText from '../components/atoms/richtext';
import Close from '../components/atoms/close';

const Project = ({ data, location }) => {
  const {
    title,
    bgColor,
    images,
    descriptions,
    _rawBottomDesc,
    photoCredits,
    country,
    link,
  } = data.projects;

  return (
    <>
      <Global
        styles={css`
          .main-wrapper {
            background: ${bgColor?.hex};
          }
        `}
      />
      <SEO siteTitle={title} />
      <StyledProject className="grid">
        <h1 className="hl" style={{ color: bgColor ? 'var(--w)' : 'black' }}>
          {title}
        </h1>
        <Close style={{ color: bgColor ? 'var(--w)' : 'black' }} />
        <div className="text">
          <div className="cols breakword">
            {descriptions.map((desc, i) => (
              <div key={i} className={`block-${i + 1}`}>
                <RichText blocks={desc._rawText} />
              </div>
            ))}
          </div>
          <div className="btm">
            <div>
              <RichText blocks={_rawBottomDesc} />
            </div>
          </div>
          <p className="credits">{photoCredits}</p>
          <p className="country">{country}</p>
          <p className="link underl">
            <a href={link?.url} target="_blank" rel="noopener noreferrer">
              {link?.title}
            </a>
          </p>
          <div className="social hl">
            <div>
              <FacebookShareButton url={location.href} className="fb">
                <p>FB</p>
              </FacebookShareButton>
            </div>

            <div>
              <TwitterShareButton url={location.href} via="EJR" className="tw">
                <p>TW</p>
              </TwitterShareButton>
            </div>

            <div>
              <LinkedinShareButton url={location.href} className="li">
                <p>LI</p>
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        <div className="images">
          {images.map(
            (item, i) =>
              (item.__typename === 'SanityMainImage' && (
                <figure key={i}>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.alt}
                  />
                </figure>
              )) ||
              (item.__typename === 'SanityVideo' && (
                <div className="iframe-wrapper">
                  <div key={i} className="iframe">
                    <iframe
                      src={
                        item.url.includes('youtube')
                          ? `https://www.youtube-nocookie.com/embed/${
                              item.url.split('?v=')[1]
                            }`
                          : `https://player.vimeo.com/video/${
                              item.url.split('.com/')[1]
                            }?dnt=1`
                      }
                      title="Video"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    />
                  </div>
                </div>
              ))
          )}
        </div>
      </StyledProject>
    </>
  );
};

const StyledProject = styled.section`
  display: flex;
  flex-direction: column;

  h1 {
    padding-top: var(--sp-1-3);
    padding-left: var(--sp-1-3);
    padding-bottom: var(--sp-5);
    color: var(--w);
    display: block;
    z-index: 10;
    max-width: 80%;
  }

  .text {
    order: 2;
    text-transform: none;
    padding-left: var(--sp-1-3);

    .cols {
      max-width: 90%;
    }

    .btm {
      padding-top: var(--sp-3);
      font-weight: bold;
      max-width: 90%;
    }

    .credits {
      font-weight: bold;
      padding-top: var(--sp-2);
      padding-bottom: var(--sp-1);
    }

    .link,
    .country {
      text-transform: uppercase;
      font-weight: bold;
    }

    .link {
      display: inline-block;
    }

    .social {
      font-size: var(--fs-hl-fix);
      display: flex;
      padding-top: var(--sp-5);
      justify-content: center;
      margin-right: var(--sp-1-3);

      div {
        width: 90px;
        height: 90px;
        display: flex;
        border: 1px solid var(--w);
        border-radius: 50%;
        margin-right: var(--sp-1);

        &:hover {
          border-color: transparent;
        }

        button {
          width: 100%;
        }

        p {
          transform: translateY(-3px);
          text-align: center;
        }
      }
    }
  }

  .images {
    order: 1;
  }

  figure,
  .iframe-wrapper {
    padding-bottom: var(--sp-3-5);
  }

  @media ${media.M} {
    display: grid;

    h1 {
      position: sticky;
      padding-bottom: var(--sp-8);
      top: 0;
      max-width: auto;
    }

    .text {
      order: 0;
      grid-column: 1 / 11;
      position: sticky;
      top: calc(var(--fs-hl) + var(--sp-8) + var(--sp-1-3));
      left: 0;
      align-self: start;

      .cols {
        display: flex;
        max-width: 70%;

        .block-1,
        .block-2 {
          width: 50%;
          padding-right: var(--sp-2);
        }
      }

      .btm {
        max-width: 70%;
      }

      .social {
        padding-top: var(--sp-2-3);
        justify-content: flex-start;
      }
    }

    .images {
      order: 0;
      grid-column: 11 / 25;
    }
  }
`;

export const query = graphql`
  query ($slug: String!) {
    projects: sanityProject(slug: { current: { eq: $slug } }) {
      _rawBottomDesc
      country
      date
      descriptions {
        _rawText
      }
      slug {
        current
      }
      title
      photoCredits
      link {
        title
        url
      }
      images {
        ... on SanityMainImage {
          __typename
          image {
            asset {
              gatsbyImageData
            }
          }
          alt
        }
        ... on SanityVideo {
          __typename
          url
        }
      }
      bgColor {
        hex
      }
    }
  }
`;

Project.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Project;
